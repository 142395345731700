import React from 'react';
import { withPrefix } from 'gatsby';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withRoot from '../withRoot';

import CookieContext from '../context/CookieContext';
import Layout from '../components/Layout';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import link from '../theme/styles/link';
import footerChevron from '../theme/styles/footerChevron';

const styles = () => ({
  ...container,
  ...section,
  ...link,
  ...footerChevron,
});

const Terms = ({ classes, location }) => (
  <Layout location={location} title="Terms and Conditions">
    <div className={classNames(classes.container, classes.section)}>
      <Typography component="h1" variant="h1">
        Cookies
      </Typography>
      <Typography variant="body1" gutterBottom>
        This website uses cookies and tracking technologies (“cookies”) to
        improve your experience while you navigate through the website. We use
        third-party cookies to collect certain information about you and your
        browsing behavior on our website. This information may include details
        about your use of the website, including clicks and your activity on the
        website. Cookies help us analyze and understand how you use this
        website, to store user preferences and provide you with content and
        advertisements that are relevant to you. You may opt-out of these
        cookies.
      </Typography>
      <Typography variant="body1" gutterBottom>
        This preference center allows you to opt out of the disclosure of
        personal information we collect through certain cookies, including
        opting of the “sale” or “sharing” of your personal information via
        cookies on this website for analytics and targeted advertising. You can
        exercise your opt out rights by clicking “Do Not Sell or Share My
        Personal Information” and saving your preferences.
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you would like to opt out of sales and sharing via an opt out
        preference signal, you can do so by utilizing a browser extension that
        supports opt out preference signals.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Depending on your state of residency, you may have additional privacy
        rights. Please visit our Privacy Policy at:{' '}
        <a
          href="https://www.servicemaster.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.servicemaster.com/privacy-policy/
        </a>{' '}
        to learn more.
      </Typography>
      <div style={{ marginTop: '3em' }}>
        <CookieContext.Consumer>
          {cookieContext => (
            <label>
              <input
                type="checkbox"
                name="tmt-eu-cookie-compliance-toggle"
                className="tmt-eu-cookie-compliance-toggle"
                value="value"
                checked={!cookieContext.cookieConsent}
                onChange={() =>
                  cookieContext.toggleCookieConsent(
                    !cookieContext.cookieConsent,
                  )
                }
              />
              Do Not Sell or Share My Personal Information
            </label>
          )}
        </CookieContext.Consumer>
      </div>
    </div>
    <div className={classes.footerChevronContainer}>
      <img
        src={withPrefix('/img/bottom--alt.svg')}
        alt=""
        className={classes.footerChevron}
      />
    </div>
  </Layout>
);

export default withRoot(withStyles(styles)(Terms));
